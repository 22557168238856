import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resource-center',
  templateUrl: './resource-center.component.html',
  styleUrls: ['./resource-center.component.scss']
})
export class ResourceCenterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
