import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-straight-talk',
  templateUrl: './straight-talk.component.html',
  styleUrls: ['./straight-talk.component.scss']
})
export class StraightTalkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
