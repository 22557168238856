import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing/landing.component';
import { LoginComponent } from './landing/login/login.component';
import { RegisterComponent } from './landing/register/register.component';
import { ForgetPasswordComponent } from './landing/forget-password/forget-password.component';
import { ResetPasswordComponent } from './landing/reset-password/reset-password.component';
import { FourofourComponent } from './fourofour/fourofour.component';
import { NavBarComponent } from './landing/nav-bar/nav-bar.component';
import { FooterComponent } from './landing/footer/footer.component';
import { ResourceCenterComponent } from './resource-center/resource-center.component';
import { NewsEventComponent } from './news-event/news-event.component';
import { StraightTalkComponent } from './straight-talk/straight-talk.component';
import { ContactComponent } from './contact/contact.component';
import { JourneyComponent } from './journey/journey.component';
import { AcademyComponent } from './academy/academy.component';
import { CommunityComponent } from './community/community.component';
import { FoundationComponent } from './foundation/foundation.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    LoginComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    FourofourComponent,
    NavBarComponent,
    FooterComponent,
    ResourceCenterComponent,
    NewsEventComponent,
    StraightTalkComponent,
    ContactComponent,
    JourneyComponent,
    AcademyComponent,
    CommunityComponent,
    FoundationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
