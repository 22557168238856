import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit {

  checkList = document.getElementsByClassName('nav');

  @HostListener("window:scroll", []) onWindowScroll() {
    // do some stuff here when the window is scrolled
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;

    if (verticalOffset > 100) {

      this.checkList.item(0).classList.add('positionfix');

    } else {

      this.checkList.item(0).classList.remove('positionfix');
    }
  }

  toggleShow = false;

  toggleShowMore = false;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  onShowToggle() {
    this.toggleShow = !this.toggleShow;
  }

  onScroll() {


  }


  onShowToggleMoreOption() {
    this.toggleShowMore = !this.toggleShowMore;

  }

}
