import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    this.carouselInterval();

  }


  carouselInterval() {

    setInterval(() => {

      let id = document.getElementsByClassName('slider-container');

      const s: HTMLCollection = id.item(0).children;

      if (s[0].classList.contains('carousel-toggle')) {

        s[0].classList.remove('carousel-toggle');

        s[1].classList.add('carousel-toggle');

      }

      else if (s[1].classList.contains('carousel-toggle')) {

        s[1].classList.remove('carousel-toggle');

        s[0].classList.add('carousel-toggle');
      }

    }, 5000);

  }

}
