import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing/landing.component';
import { FourofourComponent } from './fourofour/fourofour.component';
import { LoginComponent } from './landing/login/login.component';
import { RegisterComponent } from './landing/register/register.component';
import { ResourceCenterComponent } from './resource-center/resource-center.component';
import { NewsEventComponent } from './news-event/news-event.component';
import { StraightTalkComponent } from './straight-talk/straight-talk.component';
import { ContactComponent } from './contact/contact.component';
import { JourneyComponent } from './journey/journey.component';
import { AcademyComponent } from './academy/academy.component';
import { CommunityComponent } from './community/community.component';
import { FoundationComponent } from './foundation/foundation.component';


const routes: Routes = [
  {
    path: '', component: LandingComponent, pathMatch: 'full'
  },
  {
    path: 'about-us', component: LoginComponent, pathMatch: 'full'
  },
  {
    path: 'resource-center', component: ResourceCenterComponent
  },
  {
    path: 'news-events', component: NewsEventComponent, pathMatch: 'full'
  },
  {
    path: 'straight-talk-group', component: StraightTalkComponent, pathMatch: 'full'
  },
  {
    path: 'contact-us', component: ContactComponent, pathMatch: 'full'
  },

  {
    path: 'annie-jacobs/journey', component: JourneyComponent, pathMatch: 'full'
  },
  {
    path: 'annie-jacobs/advantage-academy', component: AcademyComponent, pathMatch: 'full'
  },
  {
    path: 'annie-jacobs/our-community', component: CommunityComponent, pathMatch: 'full'
  },
  {
    path: 'annie-jacobs/foundation', component: FoundationComponent, pathMatch: 'full'
  },
  {
    path: '*', component: FourofourComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
